export default function ({ $searchspring }) {
  // Need to remove reset cookies code after 1 month of going live
  if (!$searchspring.getResetCookieFlag()) {
    console.log("This should not be loggedddddddddddd  more than one per user");
    $searchspring.resetIsuid();
    $searchspring.resetSsUserId();
    $searchspring.resetSsSessionIdNamespace();
    $searchspring.resetSsViewedProducts();
    $searchspring.setResetCookieFlag();
  }

  if (!$searchspring.getIsuid()) {
    $searchspring.setIsuid();
  }
  if (!$searchspring.getSsUserId()) {
    $searchspring.setSsUserId();
  }
  if (!$searchspring.getSsSessionIdNamespace()) {
    $searchspring.setSsSessionIdNamespace();
  }
}
