import { intersection, evolve, reduce } from "ramda";

import { groupByProp } from "~/utils";

const getDiscount = ({ discountAllocations }) =>
  (discountAllocations ?? []).reduce(
    (acc, v) => (acc += Number(v.discountedAmount?.amount ?? 0)),
    0
  );

const getTotalDiscount = reduce(
  (acc, v) => (acc += Number((v.discount ?? 0) + (v.bundleDiscount ?? 0))),
  0
);

const getDiscountPerItem = item => getDiscount(item);

const getBundleDiscountPerItem = item => {
  if (
    item.lineComponents.length === 0 ||
    item.attributes.find(attr => attr.key === "_assembly_variant_id")
  ) {
    return 0;
  }

  const totalAmount = item.cost.totalAmount.amount;

  const compareAtPricePerQuantity = item.attributes.find(
    attr => attr.key === "_compare_at_price"
  )?.value;
  const totalCompareAtPrice = compareAtPricePerQuantity
    ? (Number(compareAtPricePerQuantity) / 100) * item.quantity
    : 0;

  return totalCompareAtPrice > totalAmount
    ? totalCompareAtPrice - totalAmount
    : 0;
};

const isOfferApplicable = (state, item) => {
  const offerDataTags = state?.offerTags ?? [];
  const productTags = item?.variant?.product?.tags ?? [];
  const commonTags = intersection(productTags, offerDataTags);
  return commonTags.length > 0;
};

const getAssemblyVariant = (state, { variant }) => {
  const assemblyRequired = variant?.assembly_required;
  if (assemblyRequired != 1) {
    return null;
  }

  const assemblySku = variant?.assembly_sku;
  const assemblyVariants = state.bagVariants?.assembly ?? [];

  return assemblySku
    ? assemblyVariants.find(item => item.sku == assemblySku)
    : null;
};

// code with bundle implementation
// const getAssemblyItem = line => {
//   return line.lineComponents.find(
//     item => item.variant.product.productType === "Assembly Service"
//   );
// };

const getAssemblyItem = (getters, lineItem) => {
  let assemblyItem = getters.lines.find(item =>
    item.attributes.find(
      attr =>
        attr.key == "_assembly-for-key" && attr.value == lineItem?.variant?.id
    )
  );

  if (!assemblyItem) {
    assemblyItem = getters.lines.find(item =>
      item.attributes.find(
        attr =>
          attr.key == "_assembly-for" && attr.value == lineItem?.variant?.sku
      )
    );
  }

  return assemblyItem;
};

const sortLineItems = items =>
  items.sort((item1, item2) => {
    return (
      parseInt(
        item2.attributes.find(attr => attr.key === "_time")?.value ?? 1
      ) -
      parseInt(item1.attributes.find(attr => attr.key === "_time")?.value ?? 1)
    );
  });

const getBonusItem = (getters, lineItem) => {
  if (!lineItem.variant.bonus_variant_id) {
    return;
  }

  const hasBonusItem = getters.bonusLineItem?.attributes.find(
    attr =>
      attr.key == `_bonus_for_${lineItem.variant.sku}` &&
      attr.value?.includes(lineItem.variant.sku)
  );

  return hasBonusItem ? getters?.bonusLineItem : null;
};

export default {
  hasQuickshipRate(state) {
    if (!state.shippingRates.length) return false;

    const quickshipRates = state.shippingRates.filter(rate =>
      rate.service_code.includes("CHGNXTD")
    );
    return quickshipRates.length > 0;
  },

  // Return collection of shipping lines
  shippingLines: (state, getters, rootState, rootGetters) => {
    if (!state.selectedShippingRate) return [];

    const partialQuickshipCond =
      getters.quickshipRateSelected &&
      rootGetters["quickship/hasPartialQuickshipItems"];
    if (!partialQuickshipCond) {
      return [
        {
          description: state.selectedShippingRate.service_name,
          amount: state.selectedShippingRate.total_price,
        },
      ];
    }

    // If free delivery is offered for all products, then standard delivery will be 0
    // Also if there is at least one M2 (which is eligible for free express delivery) order + normal express delivery order/s, total will be 0
    // If there is at least one M2 order + postal order, total will be $10
    // If there is only one quickship delivery, total will be $149
    // If there is one quickship delivery + postal item, total will be $149 + $10
    // If there is only standard delivery order/s, total will be $99/$120/$145
    // If there are quickship and standard delivery, total will be $149 + $99/$120/$145

    const shippingTotalPrice = state.selectedShippingRate.total_price;
    // Set default values for express and standard delivery
    let stnDelivery = 8800;
    let expDelivery = 14900;
    if (shippingTotalPrice == 0) {
      stnDelivery = 0;
      expDelivery = 0;
    } else if (shippingTotalPrice == 1000) {
      stnDelivery = 1000;
      expDelivery = 0;
    } else if (shippingTotalPrice == 14900) {
      stnDelivery = 0;
      expDelivery = 149000;
    } else if (shippingTotalPrice == 15900) {
      stnDelivery = 1000;
      expDelivery = 149000;
    } else if (
      shippingTotalPrice === 9900 ||
      shippingTotalPrice === 12000 ||
      shippingTotalPrice === 14500
    ) {
      stnDelivery = shippingTotalPrice;
      expDelivery = 0;
    } else if (
      shippingTotalPrice === 24800 ||
      shippingTotalPrice === 26900 ||
      shippingTotalPrice === 29400
    ) {
      stnDelivery = shippingTotalPrice - 14900;
      expDelivery = 14900;
    }

    return [
      {
        description: getters.hasQuickshipRate
          ? state.deliveryTitles.express
          : "",
        amount: expDelivery,
      },
      {
        description: state.deliveryTitles.standard,
        amount: stnDelivery,
      },
    ];
  },

  // Return true if a Quickship rate is selected
  quickshipRateSelected: state => {
    return (
      state.selectedShippingRate?.service_code?.includes?.("CHGNXTDT1") ?? false
    );
  },

  // Return true if a Quickship next-day rate is selected
  quickshipNextDayRateSelected: state => {
    return (
      state.selectedShippingRate?.service_code?.includes?.("CHGNXTDT1-NEXT") ??
      false
    );
  },

  // Return true if a Quickship express rate is selected
  quickshipExpressRateSelected: state => {
    return (
      state.selectedShippingRate?.service_code?.includes?.("CHGNXTDT1") ?? false
    );
  },

  // Return true if a pickup rate is selected
  pickupRateSelected: state => {
    return (
      state.selectedShippingRate?.service_code?.includes?.("CUSTPICKUP") ??
      false
    );
  },

  visibleQuickshipItems: (state, getters, rootState, rootGetters) => {
    return getters.visibleLineItems.filter(item =>
      rootState.quickship.items.includes(item.variant.sku)
    );
  },

  visibleOrdinaryItems: (state, getters, rootState, rootGetters) => {
    return getters.visibleLineItems.filter(
      item => !rootState.quickship.items.includes(item.variant.sku)
    );
  },

  // Fetch items in their delivery groups
  itemGroups: (state, getters, rootState, rootGetters) => {
    // Return a single unlabelled group when shipping service code not available
    const serviceCode = state.selectedShippingRate?.service_code ?? false;
    if (!serviceCode) {
      return [
        {
          title: null,
          items: getters.visibleLineItems,
        },
      ];
    }

    // Quickship selected, split groups
    if (getters.quickshipRateSelected) {
      const quickshipItems = getters.visibleQuickshipItems;
      const ordinaryItems = getters.visibleOrdinaryItems;

      return [
        {
          title: getters.quickshipExpressRateSelected
            ? state.deliveryTitles.express
            : "",
          subtitle: ordinaryItems.length ? "Delivery 1 of 2" : null,
          items: quickshipItems.map(item => ({ ...item, quickship: true })),
        },
        ordinaryItems.length && {
          title: "Standard Delivery",
          subtitle: "Delivery 2 of 2",
          items: ordinaryItems,
        },
      ].filter(Boolean);
    }

    // Return a single "Pickup" group
    if (getters.pickupRateSelected) {
      return [
        {
          title: "Pickup",
          items: getters.visibleLineItems,
        },
      ];
    }

    // Return a fallback "Standard Delivery" group
    return [
      {
        title: "Standard Delivery",
        items: getters.visibleLineItems,
      },
    ];
  },

  shouldApplyMembershipDiscount:
    (state, getters, rootState, rootGetters) => (rateName, ratePrice) => {
      const currentMembershipDiscount = Number(state.shop.membership_discount);

      if (!currentMembershipDiscount) {
        return false;
      }
      const isSnoozeMember = getters.isSnoozeMember;
      const cartSubTotal = state.cart.subTotal;

      if (isSnoozeMember && cartSubTotal > 999) {
        if (
          rateName?.toUpperCase()?.startsWith("STANDARD") &&
          ratePrice == "9900"
        ) {
          const discountedPrice =
            ratePrice - currentMembershipDiscount > 0
              ? ratePrice - currentMembershipDiscount
              : "Free";
          return discountedPrice / 100;
        }
        return false;
      }
      return false;
    },

  cartAttributes: state => groupByProp("key")(state.cart?.attributes ?? []),

  lines(state) {
    return (state.cart?.lines ?? []).map(item => ({
      ...item,
      discount: getDiscountPerItem(item),
      bundleDiscount: getBundleDiscountPerItem(item),
      isDonationItem: item.variant.product?.productType === "Donation",
      isBonusItem: item.variant.product.title === "Bonus Manchester Set",
    }));
  },

  visibleLineItems: (state, getters, rootState) => {
    let offerAdded = false;

    let items = getters.lines
      .filter(
        item =>
          ![
            "Donation to The Smith Family",
            "Bonus Manchester Set",
            "Snooze Assembly Service",
          ].includes(item.variant.product.title)
      )
      .map(item => {
        // if offer added then we will not call isOfferApplicable
        let offerApplicable = false;
        if (!offerAdded && isOfferApplicable(state, item)) {
          offerAdded = true;
          offerApplicable = true;
        }

        return {
          ...item,
          assemblyVariant: getAssemblyVariant(state, item),
          assemblyItem: getAssemblyItem(getters, item),
          offerApplicable,
          bonusItem: getBonusItem(getters, item),
        };
      });

    return sortLineItems(items);
  },

  totalVisibleLines: (state, getters) =>
    getters.visibleLineItems
      .flatMap(line =>
        line.lineComponents.length > 0 ? line.lineComponents : line
      )
      .map(line => ({ ...line, discount: getDiscount(line) })),

  discountedVisibleLineItems: (state, getters) => {
    const items = getters.visibleLineItems.map(item => {
      const variant = evolve(
        { price: value => value - item.discount / item.quantity },
        item.variant
      );

      return { ...item, variant };
    });

    return items;
  },

  cartSubTotal: s => s.cart.subTotal ?? "0",

  isAutoClubMember: (state, getters) =>
    Boolean(getters.cartAttributes?.["snooze.auto_club_details"]?.value),

  isVelocityMember: (state, getters) => Boolean(getters.velocityAttrs),

  isSnoozeMember: (state, getters) => Boolean(getters.snoozeMemberAttrs),

  donationLineItem: (state, getters) =>
    getters.lines.find(
      item => item.variant.product.title === "Donation to The Smith Family"
    ),

  // was with bundles
  // assemblyLineItems: (state, getters) =>
  //   getters.visibleLineItems
  //     .filter(line => line.assemblyItem)
  //     .map(line => line.assemblyItem),

  assemblyLineItems: (state, getters) =>
    getters.lines.filter(
      item => item.variant.product.title === "Snooze Assembly Service"
    ),

  bonusLineItem: (state, getters) =>
    getters.lines.find(
      item => item.variant.product.title === "Bonus Manchester Set"
    ),

  // line level discounts
  linesDiscountValue: (state, getters) =>
    getTotalDiscount(getters.visibleLineItems),

  // cart level discounts
  discountValue: (state, getters) => getDiscount(state.cart),

  bundleDiscountValue: (state, getters) =>
    getters.visibleLineItems.reduce(
      (acc, v) => (acc += Number(v.bundleDiscount ?? 0)),
      0
    ),

  gstAmount(state, getters) {
    const taxableLines = getters.totalVisibleLines.filter(
      item => item.variant.taxable
    );

    const totalLinesDiscount = getTotalDiscount(taxableLines);
    const cartDiscount = getDiscount(state.cart);
    const totalAmount = taxableLines.reduce(
      (acc, v) => acc + Number(v.variant?.price ?? 0) * v.quantity,
      0
    );

    const gstAmount = totalAmount - totalLinesDiscount - cartDiscount;
    return gstAmount ? gstAmount / 11 : gstAmount;
  },

  productSkus: (state, getters) =>
    getters.visibleLineItems
      .filter(({ variant }) => variant.sku.length)
      .map(({ variant }) => variant.sku),

  productQuantities: (state, getters) =>
    getters.visibleLineItems.map(item => item.quantity),

  shippingSimpleItems: (state, getters) =>
    [...getters.discountedVisibleLineItems, ...getters.assemblyLineItems]
      .filter(({ variant }) => variant.sku.length)
      .map(({ variant, ...item }) => ({
        sku: variant.sku,
        grams: variant.weight * (variant.weightUnit == "KILOGRAMS" ? 1000 : 1),
        price: Math.trunc(Number(variant?.price ?? 0) * 100),
        quantity: item.quantity,
      })),

  shippingCharge: state => state.delivery,

  hasQuickshipMetaField: (state, getters) => {
    return getters.visibleLineItems.some(item => item.variant?.quickship);
  },

  addedAssemblyAmount: (state, getters) => {
    return getters.assemblyLineItems.reduce(
      (acc, val) => (acc += val.quantity * Number(val.variant?.price ?? 0)),
      0
    );
  },

  snoozeMemberAttrs: (state, getters) => {
    const findMember = attributes =>
      attributes?.find(({ key }) => key == "_membership_details");

    const memberItem = getters.lines.find(({ attributes }) =>
      findMember(attributes)
    );
    return findMember(memberItem?.attributes);
  },

  velocityAttrs: (state, getters) => {
    const findVelocity = attributes =>
      attributes?.find(({ key }) => key == "_velocity_details");

    const velocityItem = getters.lines.find(({ attributes }) =>
      findVelocity(attributes)
    );
    return findVelocity(velocityItem?.attributes);
  },

  membershipAttr(state, getters, rootState) {
    const customer = rootState.wishlist.customer;

    return customer
      ? {
          key: "_membership_details",
          value: JSON.stringify({
            email: customer.email,
            memberId: String(customer.customer_id),
          }),
        }
      : null;
  },
};
