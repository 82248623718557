import { v4 as uuidv4 } from "uuid";

const uid = uuidv4();
const namespace = uuidv4();

const ONE_YEAR_USER_COOKIE = 60 * 60 * 24 * 365 * 1;
const LAST_VIEWED_LIMIT = 50;

export default ({ app, $config, isDev }, inject) => {
  const searchspring = {
    getIsuid() {
      return app.$cookies.get("_isuid");
    },
    setIsuid() {
      app.$cookies.set("_isuid", uid, {
        path: "/",
        maxAge: ONE_YEAR_USER_COOKIE,
        ...this.getExtraOptions(),
      });
    },
    resetIsuid() {
      app.$cookies.remove("_isuid");
    },
    getSsUserId() {
      return app.$cookies.get("ssUserId");
    },
    setSsUserId() {
      app.$cookies.set("ssUserId", uid, {
        path: "/",
        maxAge: ONE_YEAR_USER_COOKIE,
        ...this.getExtraOptions(),
      });
    },
    resetSsUserId() {
      app.$cookies.remove("ssUserId");
    },
    getSsSessionIdNamespace() {
      return app.$cookies.get("ssSessionIdNamespace");
    },
    setSsSessionIdNamespace() {
      app.$cookies.set("ssSessionIdNamespace", namespace, {
        path: "/",
        ...this.getExtraOptions(),
      });
    },
    resetSsSessionIdNamespace() {
      app.$cookies.remove("ssSessionIdNamespace");
    },
    getSsViewedProducts() {
      // limit in future will be changes as per SS team
      const cookieValue = app.$cookies.get("ssViewedProducts") ?? "";
      return cookieValue.split(",").slice(-LAST_VIEWED_LIMIT);
    },
    setSsViewedProducts(sku) {
      const viewedProducts = this.getSsViewedProducts();
      let products = viewedProducts.includes(sku)
        ? viewedProducts
        : [sku, ...viewedProducts.slice(0, LAST_VIEWED_LIMIT - 1)].filter(
            Boolean
          );

      app.$cookies.set("ssViewedProducts", products.join(","), {
        path: "/",
        maxAge: ONE_YEAR_USER_COOKIE * 7,
        ...this.getExtraOptions(),
      });
    },
    resetSsViewedProducts() {
      app.$cookies.remove("ssViewedProducts");
    },
    getExtraOptions() {
      return isDev
        ? {}
        : {
            sameSite: "lax",
            domain: $config.domainName,
            secure: true,
          };
    },
    getResetCookieFlag() {
      return Boolean(app.$cookies.get("_ssResetDomain"));
    },
    setResetCookieFlag() {
      app.$cookies.set("_ssResetDomain", 1, {
        path: "/",
        maxAge: ONE_YEAR_USER_COOKIE,
      });
    },
  };

  inject("searchspring", searchspring);
};
