
import { AisInstantSearchSsr, AisConfigure } from "vue-instantsearch";

import { createSearchSSRMixin, facetsMixin } from "~/mixins";

export default {
  name: "ListOfProducts",

  components: { AisInstantSearchSsr, AisConfigure },

  mixins: [facetsMixin],

  props: { slice: { type: Object, default: () => {} } },

  data() {
    const {
      algoliaAppId,
      algoliaKey,
      algoliaProductsIndex: indexName,
      algoliaDefaultSortProductsIndex,
    } = this.$store.state.siteConfig;

    const mixin = createSearchSSRMixin({
      algoliaAppId,
      algoliaKey,
      indexName,
      router: this.$router,
      facets: ["refinementList", "sortBy", "range", "page"],
      algoliaDefaultSortProductsIndex,
      bugsnag: this.$bugsnag,
    });

    return { ...mixin.data() };
  },

  computed: {
    handle() {
      return this.$route.params.handle;
    },
  },
};
